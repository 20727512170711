import * as Sentry from '@sentry/browser';
import { FunctionComponent, ReactNode, useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, useLocation } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import NotFoundFromUrlIdError from '../../../errors/NotFoundFromUrlIdError';
import UrlNotFoundError from '../../../errors/UrlNotFoundError';
import isDynamicImportError from '../../../errors/isDynamicImportError';
import {
  CobblestoneError,
  isCobblestoneError,
  isCobblestoneErrorWithCode,
} from '../../../errors/CobblestoneError';
import ErrorPageType from '../types/ErrorPageType';
import ErrorDisplayType from '../../../errors/types/ErrorDisplayType';
import PikLoadingScreen from '../../../base_components/pik/PikSpinner/PikLoadingScreen';

type Props = {
  children: ReactNode;
};

const ErrorHandlerLayout: FunctionComponent<Props> = ({ children }) => {
  return <ErrorBoundary FallbackComponent={GenericErrorHandler}>{children}</ErrorBoundary>;
};

export default ErrorHandlerLayout;

type GenericErrorHandlerProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

const GenericErrorHandler: FunctionComponent<GenericErrorHandlerProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const location = useLocation();
  const initialPathname = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== initialPathname.current) {
      resetErrorBoundary();
    }
  }, [location.pathname, error]);

  if (isDynamicImportError(error)) {
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
    return null;
  } else if (isCobblestoneError(error) && error.response.status === 401) {
    if (location.pathname === '/login') {
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    }

    return <Navigate to='/login?refresh=true' />;
  } else if (
    isCobblestoneError(error) &&
    error.response.data.preferred_display_type === ErrorDisplayType.REDIRECT
  ) {
    return <PikLoadingScreen />;
  }

  const [errorType, canGoHome, canGoHomeText]: [ErrorPageType, boolean, string | undefined] = (() => {
    if (isRemovedFromApplicationException(error)) {
      const extra = error.response.data.extra;
      if (extra === null) {
        return ['removed-from-app', false, undefined];
      }

      const hasAppView = !!(extra['has_application_access'] || false);
      const hasAdminView = !!(extra['has_admin_access'] || false);

      if (!hasAppView && !hasAdminView) {
        return ['removed-from-app', false, undefined];
      } else if (hasAppView && !hasAdminView) {
        return ['removed-from-app', true, 'View my other applications'];
      }

      return ['removed-from-app', true, 'Take me home'];
    } else if (error instanceof NotFoundFromUrlIdError || error instanceof UrlNotFoundError) {
      return ['404', true, undefined];
    } else {
      return ['generic', true, undefined];
    }
  })();

  if (errorType === 'generic') {
    Sentry.captureException(error);
  }

  return <ErrorPage errorType={errorType} canGoHome={canGoHome} canGoHomeText={canGoHomeText} />;
};

type RemovedFromApplicationException = CobblestoneError<{
  has_application_access: boolean;
  has_admin_access: boolean;
} | null>;

const isRemovedFromApplicationException = (error: unknown): error is RemovedFromApplicationException => {
  return isCobblestoneErrorWithCode(error, 'FORBIDDEN_0001');
};
